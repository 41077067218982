<template>
  <div v-if="isDraggable" ref="el" class="DialogContent draggable" :style="style">
    <slot></slot>
  </div>
  <div v-else class="DialogContent">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useDraggable } from "@vueuse/core";
withDefaults(defineProps<{ isDraggable?: boolean }>(), {
  isDraggable: false,
});

const el = ref<HTMLElement | null>(null);
const { style } = useDraggable(el, {
  initialValue: {
    x: window.innerWidth / 2 - 200,
    y: window.innerHeight / 2 - 200,
  },
});
</script>

<style lang="scss">
.DialogContent {
  --margin: 96px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 512px;
  width: 100%;
  margin-top: var(--margin);
  height: fit-content;
  max-height: calc(100% - 2 * var(--margin));
  background: var(--white);
  border-radius: 10px;
  box-shadow: var(--shadow-3);
  animation: dialogShow 200ms cubic-bezier(0.16, 1, 0.3, 1);

  &.draggable {
    --margin: 0px; /** fixes an issue where on grabbing the dialog, the dialog was shifting down */

    position: fixed;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  @keyframes dialogShow {
    from {
      opacity: 0;
      transform: translateY(24px);
    }

    to {
      opacity: 1;
    }
  }
}
</style>
